import React from 'react';

const NotFoundPage = () => {

  return (
    
      <div>Pagina no encontrada</div>
    
  )
};

export default NotFoundPage;